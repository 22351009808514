<template>
  <u-modal v-model="internalValue">
    <u-form
      :schema="schema"
      :state="form"
      class="space-y-4 p-4"
      autocomplete="off"
      @submit="onSubmit"
    >
      <h5>{{ cartExtra ? 'Edit' : 'Add' }} Extra</h5>
      <div class="flex flex-col gap-y-2">
        <div
          v-if="typeof extra.photo_url === 'string'"
          :class="{
            'min-h-[10vw]': true,
            'min-w-[10vw]': true,
            'max-h-64': true,
            'max-w-64': true,
            'flex': true,
            'items-center': true,
            'overflow-hidden': true,
            'mx-auto': true,
          }"
        >
          <nuxt-img
            :src="extra.photo_url as string"
            :alt="`Image for ${extra.name}`"
            class="w-full cursor-zoom-in rounded-sm object-cover"
            draggable="false"
            @click="extra.photo_url && showImage(extra.photo_url)"
          />
        </div>
        <h6 class="mb-4 font-medium">
          {{ extra.name }}
        </h6>
        <!-- eslint-disable vue/no-v-html -->
        <p
          v-if="extra.description.length && extra.name !== extra.description"
          class="py-1"
          v-html="useLinkify(extra.description)"
        />
        <!-- eslint-enable vue/no-v-html -->
        <p>
          {{ useMoneyFormat(extraPrice) }}
        </p>
        <p v-if="!useIsInt4Max(extra.quantity)">
          {{ stock > 0 ? `Available: ${stock}` : 'Out of stock' }}
        </p>
        <template v-if="typeof extra.metadata.variants === 'object'">
          <u-form-group
            v-for="group in Object.values(extra.metadata.variants).reduce<Set<string>>(
              (acc, curr) => {
                acc.add(curr.group)

                return acc
              },
              new Set(),
            )"
            :key="group"
            :label="group"
            :name="`variant.${group}`"
          >
            <u-select-menu
              v-model="form[`variant.${group}`]"
              :options="
                useSortedVariants(
                  Object.values(extra.metadata.variants ?? {})
                    .filter((v) => v.group === group)
                    .map((v) => ({
                      label: v.name,
                      value: v.name,
                    })),
                )
              "
              value-attribute="value"
            />
          </u-form-group>
        </template>
        <u-form-group
          label="Quantity"
          name="quantity"
        >
          <u-select-menu
            v-model.number="quantity"
            :options="Array.from({ length: stock }, (_, i) => i + 1)"
          />
        </u-form-group>
        <dynamic-form
          v-if="info"
          v-model="info.state"
          :input="info.input"
          :schema="info.schema"
        />
      </div>
      <div class="flex gap-4 pt-8">
        <u-button
          color="white"
          @click="internalValue = false"
        >
          Close
        </u-button>
        <u-button
          type="submit"
          :loading="loading"
          :disabled="!schema.isValidSync(form)
            || (info && !info.isValid)
            || (cartExtra
              && quantity === cartExtra.quantity_requested
              && useDeepEqual(cartExtra.variants_requested, extraVariants)
              && useDeepEqual(cartExtra.info_submitted, info.state)
            )
            || loading"
        >
          {{ cartExtra ? 'Save changes' : 'Add to cart' }}
        </u-button>
      </div>
    </u-form>
  </u-modal>
  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="imgsRef"
    :rotate-disabled="true"
    :zoom-scale="0.5"
    @hide="onHide"
  />
</template>

<script lang="ts" setup>
import { object, type ObjectShape, string } from 'yup'

import VueEasyLightbox from 'vue-easy-lightbox'
import useForm from '@tarcltd/form-vue'

const visibleRef = ref(false)
const imgsRef: Ref<string[]> = ref([])
const props = withDefaults(
  defineProps<{
    modelValue: boolean
    subuserId: string
    event: PortalEvent
    extra: PortalExtra
    stock: number
    cartExtra?: PortalCartExtra
    loading?: boolean
  }>(),
  {
    cartExtra: undefined,
    loading: false,
  },
)
const emit = defineEmits(['update:modelValue', 'addToCart'])
const internalValue = ref(props.modelValue)
const quantity = ref(props.cartExtra?.quantity_requested ?? 1)
let schema = object()
const form = ref<Record<string, string>>({})
const info = ref<ReturnType<typeof useForm>>()
const extraVariants = computed(() =>
  Object.entries(form.value).reduce<Record<string, unknown>>((acc, [key, value]) => {
    if (key.startsWith('variant.')) {
      acc[key.replace('variant.', '')] = value
    }

    return acc
  }, {}),
)
const extraPrice = ref(0)

function showImage(photoUrl: string) {
  imgsRef.value = [photoUrl]
  visibleRef.value = true
  internalValue.value = false
}

function onHide() {
  visibleRef.value = false
  internalValue.value = true
}

function constructForm(extra: PortalExtra) {
  const newSchema: ObjectShape = {}

  form.value = {}

  for (const key in extra.metadata.variants ?? {}) {
    const formKey = `variant.${extra.metadata.variants[key].group}`

    form.value[formKey]
      = ((props.cartExtra?.variants_requested ?? {})[
        extra.metadata.variants[key].group
      ] as string) ?? ''
    newSchema[formKey] = string().required(`${key} is required.`)
  }

  if (typeof extra.metadata.info === 'object'
    && typeof extra.metadata.info.properties === 'object'
    && Array.isArray(extra.metadata.info.required)) {
    info.value = useForm(extra.metadata.info)

    for (const key in info.value.input.properties) {
      if (!(key in (props.cartExtra?.info_submitted ?? {}))) {
        continue
      }

      info.value.state[key] = props.cartExtra?.info_submitted[key]
    }
  }
  else {
    info.value = undefined
  }

  schema = object(newSchema)

  quantity.value = props.cartExtra?.quantity_requested ?? 1
}

function onSubmit() {
  emit('addToCart', {
    old: props.cartExtra,
    new: {
      type: 'extra',
      event_id: props.event.id,
      subuser_id: props.subuserId,
      quantity_requested: quantity.value,
      variants_requested: extraVariants.value,
      info_submitted: info.value?.state ?? {},
    },
  } as {
    old?: PortalCartExtra
    new: PortalCartExtra
  })
}

watch(() => props.extra, constructForm, { immediate: true })

watch(
  [() => props.extra, extraVariants],
  ([updatedExtra, updatedVariants]) => {
    let computedPrice = updatedExtra.price

    for (const group in updatedVariants) {
      const variant = Object.values(updatedExtra.metadata.variants ?? {}).find(
        (v) => v.group === group && v.name === updatedVariants[group],
      )

      computedPrice += variant?.diff ?? 0
    }

    extraPrice.value = Math.max(0, computedPrice)
  },
  { immediate: true, deep: true },
)

watch(internalValue, (value) => {
  emit('update:modelValue', value)

  if (value) {
    constructForm(props.extra)
  }
})

watch(
  () => props.modelValue,
  (value) => {
    internalValue.value = value
  },
)
</script>
