/* eslint-disable @typescript-eslint/no-explicit-any */
export function useSortedVariants(arr: any[]) {
  const collator = new Intl.Collator('en-US', {
    numeric: true,
    sensitivity: 'base',
  })
  const tShirtSizes = useDefaultTShirtSizes()

  return arr.sort((a, b) => {
    const aIndex = tShirtSizes.indexOf(a.label)
    const bIndex = tShirtSizes.indexOf(b.label)

    if (aIndex !== -1 && bIndex !== -1) {
      if (aIndex < bIndex) {
        return -1
      }
      if (aIndex > bIndex) {
        return 1
      }

      return 0
    }

    return collator.compare(a.label, b.label)
  })
}
